<template>
    <div>
         <a-card style="margin-bottom:20px">
            <a-form layout="inline">
                
                <a-form-item class="dep" label="商品名称:">
                    <a-input v-model="name" placeholder="商品名称"></a-input>
                </a-form-item>
                <a-form-item>
                <a-button type="primary" icon="search" @click="handleClickSearch">
                    搜索
                </a-button>    
                </a-form-item>
            </a-form>
        </a-card>
         <a-card>
            <a-row style="text-align:right;margin-bottom:20px;">
                <a-button type="primary" @click="handleAdd">新增</a-button>
            </a-row>
            <a-table
                :columns="columns"
                :data-source="tableData"
                :row-key="(record) => record._id"
                :pagination="pagination"
                :loading="loading"
                @change="handleTableChange"
                >
                <span slot="price" slot-scope="text">{{(text/100).toFixed(2)}}</span>
                <a-tag :color="text==2?'green':'red'" slot="status" slot-scope="text">{{text==2?'上架':'下架'}}</a-tag>
                <template slot="time" slot-scope="text">{{text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}</template>
                <template slot="action" slot-scope="text,record">
                    <div class="operation-wrapper">
                        <a-dropdown>
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                编辑
                            </a>
                            <a-menu slot="overlay">
                                <a-menu-item>
                                  <a-button type="link" @click="updateProd(record)">修改</a-button>
                                </a-menu-item>
                                <a-menu-item v-if="record.status==2">
                                  <a-button type="link" @click="upDown(record,1)">下架</a-button>
                                </a-menu-item>
                                <a-menu-item v-else>
                                   <a-button type="link" @click="upDown(record,2)">上架</a-button>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                        <a-button type="link" style="color:red;" @click="handleDelete(record)">删除</a-button>
                    </div>  
                </template>
            </a-table>
         </a-card>
         <a-modal v-model="visible" title="新增商品" @ok="handleSubmit">
             <div class="tips">图片列表中的第一张图片用于主图，其余图片为详情,价格最小单位为'分'</div>
            <a-form  >
                <a-form-item 
                    label="商品名称" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-input v-model="addForm.description" />
                </a-form-item>
                <a-form-item 
                    label="商品编码" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-input v-model="addForm.product_sn" />
                </a-form-item>
                <a-form-item 
                    label="价格(元)" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-input type="number" v-model="addForm.price"  />
                </a-form-item>
                <a-form-item 
                    label="商品图片" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 18 }"
                    >
                    <a-upload
                        accept='.jpg,.jpeg,.png'
                        action="scrm/upload.file"
                        list-type="picture-card"
                        :file-list="uploadList"
                        @preview="handlePreview"
                        @change="handleChange"
                    >
                        <div v-if="fileList.length < 9">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">
                            上传
                            </div>
                        </div>
                    </a-upload>
                </a-form-item>
                
            </a-form>
        </a-modal>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%;margin-top:20px" :src="previewImage" />
        </a-modal>
        <a-modal v-model="updateVisible" title="修改商品" @ok="handleUpdateSubmit">
            <div class="tips">图片列表中的第一张图片用于主图，其余图片为详情，价格最小单位为'分'</div>
            <a-form  >
                <a-form-item 
                    label="商品名称" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-input v-model="productObj.description" />
                </a-form-item>
                <a-form-item 
                    label="商品编码" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-input v-model="productObj.product_sn" />
                </a-form-item>
                <a-form-item 
                    label="价格(元)" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-input type="number" v-model="productObj.price"  />
                </a-form-item>
                <a-form-item 
                    label="商品图片" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 18 }"
                    >
                    <a-upload
                        accept='.jpg,.jpeg,.png'
                        action="scrm/upload.file"
                        list-type="picture-card"
                        :file-list="uploadList"
                        @preview="handlePreview"
                        @change="handleUpdateChange"
                    >
                        <div v-if="fileList.length < 9">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">
                            上传
                            </div>
                        </div>
                    </a-upload>
                </a-form-item>
                
            </a-form>
        </a-modal>
    </div>
</template>
<script>
const columns = [
   {
      title: "商品名称",
      dataIndex: "description",
      ellipsis: true
    },
    {
      title: "价格(元)",
      dataIndex: "price",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: "price" },
    },
    {
      title: "商品编码",
      dataIndex: "product_sn",
      align: "center",
      ellipsis: true
    },
    {
      title: "商品状态",
      dataIndex: "status",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: "status" },
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: "time" },
    },
    {
        title: "操作",
        width: 120,
        align: "center",
        ellipsis: true,
        scopedSlots: { customRender: "action" },
    }
]
import {
    mapState
  } from 'vuex'
export default {
    name:'product',
    data(){
        return{
            name:'',
            visible:false,
            loading:false,
            previewVisible:false,
            updateVisible:false,
            columns,
            pagination:{
                total: 0,
                current: 1,
                pageSize: 10, //每页中显示10条数据
                showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
            },
            previewImage:'',
            addForm:{
                description:'',
                product_sn:'',
                price:null
            },
            tableData:[],
            fileList:[],
            uploadList:[],
            productObj:{}
        }
    },
    computed: {
        ...mapState(['mqttInit'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.handleClickSearch()
                }
            },
            immediate:true
        }
    },
    methods:{
        upDown(row,status){
            let payload = {
                msgId:'upAndDown',
                data:{
                    _id:row._id,
                    status
                }
            }
            this.$mqtt.doPublish(
                {
                    pubTopic: "scrm/media/upAndDown",
                    payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.$message.success('操作成功')
                        this.handleGetProducts()
                    }
                },
                this
            );
        },
        handleAdd(){
            this.addForm = {
                description:'',
                product_sn:'',
                price:null
            }
            this.fileList = []
            this.uploadList = []
            this.visible = true
        },
        handleClickSearch(){
            this.pagination.current = 1
            this.handleGetProducts()
        },
        handleGetProducts(){
             let payload = {
                msgId:'listProducts',
                data:{
                    pageSize:this.pagination.pageSize,
                    pageNum:this.pagination.current                }
            }
            this.$mqtt.doPublish(
                {
                    pubTopic: "scrm/media/listProducts",
                    payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.tableData = data.data.res
                        this.pagination.total = data.data.count
                    }
                },
                this
            );
        },
        handleTableChange(pagination){
            this.pagination = pagination
            this.handleGetProducts()
        },
        deleteProduct(row){
            let payload = {
                msgId:'delProduct',
                data:{
                    product_id:row.product_id
                }
            }
            this.$mqtt.doPublish(
                {
                    pubTopic: "scrm/media/delProduct",
                    payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.$message.success('删除成功')
                        this.handleGetProducts()
                    }
                },
                this
            );
        },
        handleDelete(row){
            let that = this
            this.$confirm({
                title: '',
                content: '您确定要删除此商品吗？',
                okText: '确认',
                cancelText: '取消',
                onOk() {
                    that.deleteProduct(row)
                },

            });
            
        },
        updateProd(record){
            let arr = record.attachments
            this.uploadList = []
            this.fileList = []
            arr.forEach((item,index)=>{
                let obj = {
                    uid:index+1,
                    name:item.url,
                    thumbUrl:item.url,
                    url:item.url,
                    media_id:item.image.media_id
                }
                this.uploadList.push(obj)
                this.fileList.push(obj)
            })
            
            this.productObj = {...record}
            this.productObj.price = (this.productObj.price/100).toFixed(2)
            this.updateVisible = true
        },
        handleUpdateSubmit(){
             if(!this.productObj.description){
                this.$message.error('商品名称不能为空')
                return
            }
            if(!this.productObj.price){
                this.$message.error('请输入价格')
                return
            }
            if(this.fileList.length==0){
                this.$message.error('请上传商品图片')
                return
            }
            let attachments = []
            this.fileList.forEach(item=>{
                let obj = {
                    "type":"image",
                    "url":item.url||item.response.url,
                    "image":{
                        "media_id":item.media_id,
                    }
                }
                attachments.push(obj)
            })
            this.productObj.price = this.productObj.price*100
             let payload = {
                msgId:'updateProduct',
                data:{
                    ...this.productObj,
                    attachments
                }
            }
            this.$mqtt.doPublish(
                {
                    pubTopic: "scrm/media/addProduct",
                    payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.handleClickSearch()
                        this.$message.success('修改成功')
                        this.updateVisible = false
                    }
                },
                this
            );
        },
        handleSubmit(){
            if(!this.addForm.description){
                this.$message.error('商品名称不能为空')
                return
            }
            if(!this.addForm.price){
                this.$message.error('请输入价格')
                return
            }
            if(this.fileList.length==0){
                this.$message.error('请上传商品图片')
                return
            }
            let attachments = []
            this.fileList.forEach(item=>{
                let obj = {
                    "type":"image",
                    "url":item.url||item.response.url,
                    "image":{
                        "media_id":item.media_id,
                    }
                }
                attachments.push(obj)
            })
            this.addForm.price = this.addForm.price*100
            let payload = {
                msgId:'addProduct',
                data:{
                    ...this.addForm,
                    attachments
                }
            }
            this.$mqtt.doPublish(
                {
                    pubTopic: "scrm/media/addProduct",
                    payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.handleClickSearch()
                        this.$message.success('新增成功')
                        this.visible = false
                    }
                },
                this
            );

        },
        async handleChange(info) {
            this.uploadList = info.fileList
            if (info.file.status === 'uploading') {
                return;
            }
            if (info.file.status === 'done') {
                let data = await this.uploadMedia(info.file.response.url,'image')
                if(data.success){
                    this.fileList.push({...info.file,media_id:data.media_id})
                }else{
                    // this.fileList.push({...info.file,status:'error'})
                    this.$message.error('上传临时素材失败')
                }
                
            }
            if(info.file.status === 'removed'){
                let ind = this.fileList.findIndex(item=>item.uid === info.file.uid)
                if(ind !==-1){
                    this.fileList.splice(ind,1)
                }
            }
            
        },
        async handleUpdateChange(info) {
            this.uploadList = info.fileList
            if (info.file.status === 'uploading') {
                return;
            }
            if (info.file.status === 'done') {
                let data = await this.uploadMedia(info.file.response.url,'image')
                if(data.success){
                    this.fileList.push({...info.file,media_id:data.media_id})
                }else{
                    // this.fileList.push({...info.file,status:'error'})
                    this.$message.error('上传临时素材失败')
                }
                
            }
            if(info.file.status === 'removed'){
                let ind = this.fileList.findIndex(item=>item.uid === info.file.uid)
                if(ind !==-1){
                    this.fileList.splice(ind,1)
                }
            }
            
        },
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await this.getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        handleCancel(){
            this.previewVisible = false
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        uploadMedia(url,type){
            return new Promise(resolve=>{
                let payload = {
                    msgId:'getMedia',
                    data:{
                        url,
                        type
                    }
                }
                this.$mqtt.doPublish(
                    {
                    pubTopic: "scrm/media/getProMedia",
                    payload,
                    },
                    (topic, data) => {
                        resolve(data)
                    },
                    this
                );
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.operation-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tips{
    color: #FECD51;
    text-align: center;
}
</style>